import React from "react"

import styles from "../../styles/main.module.css"

export const footnote = (id, text) => (
    <p id={"footnote" + id.toString()} className={styles.footnote}>
      [{id.toString()}] {text}{" "}<a href={"#footlink" + id.toString()}>(return)</a>
    </p>
)

export const link = (href, text, id) => (
    <a id={id} href={href}>
      {text}
    </a>
)

export const footlink = (id) => link (
    "#footnote" + id.toString(), 
    "[" + id.toString() + "]", 
    "footlink" + id.toString()
)

export default (props) => <div />